import React from 'react'
import AppWrapper from '../app/layouts/AppWrapper'
import LegalInformationPage from '../ui/pages/LegalInformationPage'
import HelmetComponent from '../ui/layouts/HelmetComponent'
import metaTags from '../config/meta-tags/en.json'

const Page = props => (
  <>
    <HelmetComponent
      title={metaTags.indexTitle}
      description={metaTags.dataprivacy}
      page="/data-privacy"
    />
    <AppWrapper {...props}>
      <LegalInformationPage />
    </AppWrapper>
  </>
)

export default Page
